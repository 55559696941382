.messageBoxContainer {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    overflow: auto;
}

.messageBoxRoot {
    display: flex;
    flex-direction: row;
    /*position: fixed;*/
    width: 100%;
    overflow: hidden;
    height: 45px;
}

.formControl {
    width: 100%;
    min-height: 45px;
}

.sendButton {
    position: fixed;
    z-index: 1;
    top: auto;
    right: auto;
    color: #48c3e3;
    margin-right: 20px !important;
}

.textArea {
    margin-top: 8px;
    width: 90%;
    margin-left: 5vw;
}

.choiceFields {
    height: 4rem;
    width: 90%;
    /*overflow: auto;*/
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    padding-top: 5px;
    background: #E9E9EB;
}

.hideMessageButton {
    display: none;
}

.deactiveMessageButton {
    height: 100%;
    background: lightgray;
    border-radius: 3px;
    color: black;
    border: none;
    outline: none;
    /*box-shadow: 0 2px 10px rgba(81,193,228,1);*/
}


.messageButton {
    height: 90%;
    background: #48c3e3;
    border-radius: 3px;
    color: white;
    border: none;
    outline: none;
    box-shadow: 0 2px 10px rgba(0,0,0,.5);
}

.messageButton:hover {
    cursor: pointer;
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
    transition: opacity 0.3s ease-in-out;
    transform: translateY(-2px);
}

.messageButton:active {
    box-shadow: 0 2px 10px rgba(0,0,0,.5);
    transform: translateY(0);
}

.emojiPicker {
    position: absolute;
    bottom: 110px;
    right: 0px;
}
