@keyframes appear {
    from {
        transform: translateY(40px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.question_root{
    animation: appear 0.4s ease-out;
    align-content: center;
}

.iconBot{
    width: 50px;
    margin-left: 0.8em;
}



.textColorBot {
    color: #48c3e3;
    /*font-family: Josefin Sans;*/
    /*font-family: Arial;*/
    line-height:26px;
    font-size: 20px;
    padding: 3px;
    padding-top: 10px;
}

@media only screen and (max-width: 430px) {
    .link{
        margin-left: 3vw;
    }
}
