/*@import url('https://fonts.googleapis.com/css?family=Josefin+Sans&display=swap');*/

@keyframes appear {
    from {
        transform: translateY(40px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.topMargin {
    margin-top: 20px;
}

.textColorBot {
    white-space: pre-wrap;
    color: white;
    word-break: break-word;
    /*font-family: Josefin Sans;*/
    /*font-family: Arial;*/
    line-height: 26px;
    font-size: 18px;
    /*text-align: justify;*/

    /*-ms-transform: translateY(-10%);*/
    /*transform: translateY(-10%);*/
    margin-left: 3vw;
    background: #48c3e3;
    border-radius: 10px;
    padding: 10px;
}

.textColorUser {
    white-space: pre-wrap;
    color: black;
    /*font-family: Josefin Sans;*/
    /*font-family: Arial;*/
    line-height: 26px;
    font-size: 18px;
    -ms-transform: translateY(-10%);
    transform: translateY(-10%);
    padding: 10px 10px;
    background: aliceblue;
    border-radius: 10px;
    margin-right: 10px;
}

.userMessageTime {
    color: dimgray;
    text-align: right;
    font-size: 12px;
    margin-right: 15px;
}

.links {
    justify-content: center;
    /*margin-left: 20vw;*/
    margin-top: 10px;
    word-wrap: break-word;
}

.linkContainer {
    padding-left: 5px;
    padding-right: 5px;
}

.lastlink {
    display: flex;
    flex-direction: column;
}

.link {
    justify-content: center;
    /*margin-left: 15vw;*/
    margin-top: 10px;
    margin-bottom: 10px;
    word-wrap: break-word;
}


.messageBubble {
    position: relative;
    animation: appear 0.4s ease-out;
    margin: 5px;
    padding: 5px;
}

.messageContainerUser {
    margin-top: 0;
}

.messageContainerBot {
    margin-top: -1px;
}

.messageContainerBotFirst {
    margin-top: -1px;
}

.backgroundBot {
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    width: fit-content;
    align-self: flex-start;
    max-width: 80%;
}

.backgroundUser {
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    width: fit-content;
    align-self: flex-end;
    max-width: 80%;
}

.iconBot {
    width: 30px;
    align-self: flex-start;
}

.iconUser {
    width: 40px;
    align-self: flex-start;
}

@media only screen and (max-width: 430px) {
    .textColorBot {
        text-align: left;
    }

    .textColorUser {
        text-align: left;
    }

    /*.messageContainerBotFirst {*/
    /*    margin-top: 40px;*/
    /*}*/
}

@media only screen and (max-width: 380px) {
    .textColorBot {
        text-align: left;
    }

    .textColorUser {
        text-align: left;
    }

    /*.messageContainerBotFirst {*/
    /*    margin-top: 40px;*/
    /*}*/
}

@media only screen and (max-width: 340px) {
    .textColorBot {
        text-align: left;
    }

    .textColorUser {
        text-align: left;
    }

    /*.messageContainerBotFirst {*/
    /*    margin-top: 40px;*/
    /*}*/
}

.linkButton {
    word-break: break-word;
}

