.divRoot {
    border: 1px solid inherit;
    box-shadow: 0px 10px 40px #00000029;
    border-radius: 10px;
    background-color: white;
}

.chatHeader {
    height: 5rem;
    background: #48c3e3;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    display: flex;
}

.chatHeaderLoader {
    height: 5rem;
    background: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    display: flex;
}

.headerLogo {
    width: 3rem;
    height: 3rem;
    margin: auto 0 auto 2rem;
    border-radius: 50%;
}

.chatSpace {
    height: calc(100vh - 110px);
    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll;
}

.chatSpace1 {
    height: calc(100vh - 190px);
    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll;
}

.messageBox {
    width: 100%;
    height: 5rem;

}

/*.chatSpace::-webkit-scrollbar {*/
/*    display: none;*/
/*    overflow: -moz-scrollbars-none;*/
/*    -ms-overflow-style: none;*/
/*}*/

/*.chatSpace1::-webkit-scrollbar {*/
/*    display: none;*/
/*    overflow: -moz-scrollbars-none;*/
/*    -ms-overflow-style: none;*/
/*}*/

.header {
    color: white;
    /*min-width: 30%;*/
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /*margin-left: 10px;*/
}

.footer {
    width: 100%;
    height: 30px;
    background-color: #E9E9EB;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
}

.footerSpan1 {
    color: gray;
}

.footerSpan {
    padding-left: 4px;
    color: #48c3e3;
}



