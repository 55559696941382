.typingLoader{
    margin: 20px;
    width: 6px;
    height: 6px;
    -webkit-animation: line 0.7s ease infinite alternate;
    -moz-animation: line 0.7s ease infinite alternate;
    animation: line 0.7s ease infinite alternate;
}
@-webkit-keyframes line{
    0%{

        background-color: white;
        box-shadow: 12px 0px 0px 0px #48c3e3,
        24px 0px 0px 0px #48c3e3;

    }
    25%{
        background-color: #48c3e3;
        box-shadow: 12px 0px 0px 0px #48c3e3,
        24px 0px 0px 0px #48c3e3;

    }
    75%{ background-color: #48c3e3;
        box-shadow: 12px 0px 0px 0px #48c3e3,
        24px 0px 0px 0px #48c3e3;

    }
}

@-moz-keyframes line{
    0%{

        background-color: white;
        box-shadow: 12px 0px 0px 0px #51C1B4,
        24px 0px 0px 0px #51C1B4;

    }
    25%{
        background-color: #48c3e3;
        box-shadow: 12px 0px 0px 0px #48c3e3,
        24px 0px 0px 0px #48c3e3;

    }
    75%{ background-color: #48c3e3;
        box-shadow: 12px 0px 0px 0px #48c3e3,
        24px 0px 0px 0px #48c3e3;

    }
}

@keyframes line{
    0%{

        background-color: white;
        box-shadow: 12px 0px 0px 0px #51C1B4,
        24px 0px 0px 0px #51C1B4;

    }
    25%{
        background-color: #48c3e3;
        box-shadow: 12px 0px 0px 0px #48c3e3,
        24px 0px 0px 0px #48c3e3;

    }
    75%{ background-color: #48c3e3;
        box-shadow: 12px 0px 0px 0px #48c3e3,
        24px 0px 0px 0px #48c3e3;

    }
}
