@keyframes appear {
    from {
        transform: translateY(40px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.formControl {
    width: 100%;
    min-height: 50px;
}

.sendButton {
    vertical-align: text-bottom;
    align-self: flex-end;
    vertical-align: bottom;
}

.textArea {
    margin-top: 8px;
    width: 90%;
    margin-left: 5vw;
}

.choiceFields {
    animation: appear 0.4s ease-out;
    align-self: center;
    /*overflow: auto;*/
}
